import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Head from "next/head";
import { useRouter } from "next/router";
import requireNonAuth from "components/requiresNonAuth";
import LoginForm from "components/LoginForm";
import Link from "components/NavLink";
import NewFormTermsCondition from "components/FormTermsCondition/newFormTermsCondition";
import SocialLogin from "components/SocialLogin";
import { authPage } from "i18n/messages/meta";
import { useFormattedMessage, useUtils } from "hooks";
import { selectEnteredUrl } from "store/app/selectors";
import { TENANTS_WITH_REGISTRATION_DISABLED } from "constants/index";

const FINX_COOKIE = require("common/constants");

const messages = {
    welcomeBack: "welcome_back",
    loginContinue: "continue_login",
    loginCountinueRestricted: "login_restricted",
    notYetAccount: "not_yet_account",
    joinNow: "join_now",
    readyLogin: "registered_already",
    readyLoginPara1: "could_it_be_that_emial",
    readyLoginPara2: "already_in_use",
    readyLoginPara3: "user_your_existing_account",
};

const AuthPage = ({ linkClick = "", header, entered_url }) => {
    const { formatMessage } = useFormattedMessage();
    const { tenant } = useUtils();
    const router = useRouter();

    const ENTERED_URL = Cookies.get(FINX_COOKIE.ENTERED_URL) || entered_url;
    const [userTenants, setUserTenants] = useState();

    useEffect(() => {
        setUserTenants(router?.query?.userTenants);
    }, [router?.query]);

    return (
        <>
            <Head>
                <title>{header ? header : formatMessage(authPage.title)}</title>
            </Head>

            <div className="container small-main broad-container">
                <section className="authP login-page">
                    <div className="container small-container">
                        <div className="head">
                            {router?.query?.userExists ? (
                                <div className="login-with-account">
                                    <img src="/images/ready-login.png" alt="Logo Image" />
                                    <h3 className="mt-10">{formatMessage(messages.readyLogin)}</h3>
                                    <p className="body-medium text-left">
                                        {formatMessage(messages.readyLoginPara1)}{" "}
                                        <span className="fw-600">{router?.query?.userExists}</span>{" "}
                                        {formatMessage(messages.readyLoginPara2)}
                                    </p>
                                    <ul className="body-medium text-left mb-3">
                                        {/* <li>finexity.com</li> */}
                                        {userTenants &&
                                            (Array.isArray(userTenants) ? (
                                                userTenants.map((element, index) => <li key={index}>{element}</li>)
                                            ) : (
                                                <li>{userTenants}</li>
                                            ))}
                                    </ul>
                                    <p className="body-medium text-left">{formatMessage(messages.readyLoginPara3)}</p>
                                </div>
                            ) : (
                                <>
                                    {ENTERED_URL && <img src={"/images/lock.png"} alt="lock" />}
                                    {ENTERED_URL ? (
                                        <h2>{formatMessage(messages.loginContinue)}</h2>
                                    ) : (
                                        <h2>{formatMessage(messages.welcomeBack)}</h2>
                                    )}
                                    <span className="body-medium fw-500">
                                        {ENTERED_URL
                                            ? formatMessage(messages.loginCountinueRestricted)
                                            : !TENANTS_WITH_REGISTRATION_DISABLED.includes(tenant?.TechnicalName) &&
                                              formatMessage(messages.notYetAccount)}{" "}
                                        {ENTERED_URL
                                            ? ""
                                            : typeof linkClick === "function"
                                            ? !TENANTS_WITH_REGISTRATION_DISABLED.includes(tenant?.TechnicalName) && (
                                                  <span className="primary-color d-inline-block" onClick={linkClick}>
                                                      {formatMessage(messages.joinNow)}
                                                  </span>
                                              )
                                            : !TENANTS_WITH_REGISTRATION_DISABLED.includes(tenant?.TechnicalName) && (
                                                  <Link href="/register" className="primary-color d-inline-block">
                                                      {formatMessage(messages.joinNow)}
                                                  </Link>
                                              )}
                                    </span>
                                </>
                            )}
                        </div>
                        <div className="text-center new-register-form px-4 overflow-hidden">
                            <LoginForm
                                selectedEmail={router?.query?.userExists ? router?.query?.userExists : null}
                                isShowSeparatorLine={router?.query?.userExists ? false : true}
                            />
                            {router?.query?.userExists ? null : (
                                <>
                                    <SocialLogin />
                                    <NewFormTermsCondition className="mt-8 text-center" />
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    entered_url: selectEnteredUrl,
});

export default compose(requireNonAuth, connect(mapStateToProps))(AuthPage);
