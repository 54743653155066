import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Router from "next/router";

import { selectIsUserAuthenticated } from "store/user/account/selectors";

const RequireNonAuth = (ComposedComponent) => {
    const NonAuthenticate = ({ isAuthenticated }) => {
        React.useEffect(() => {
            if (isAuthenticated) Router.push("/dashboard");
        }, [isAuthenticated]);
        return <ComposedComponent />;
    };

    const mapStateToProps = createStructuredSelector({
        isAuthenticated: selectIsUserAuthenticated,
    });

    return connect(mapStateToProps)(NonAuthenticate);
};

export default RequireNonAuth;
