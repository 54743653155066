import React from "react";
import { useFormattedMessage } from "hooks";

const messages = {
    or: "auth_account_or",
};

function OrSeparatorLine({ isLogin }) {
    const { formatMessage } = useFormattedMessage();

    if (isLogin) {
        return (
            <div className="flex-row line-holder mt-10 mb-4">
                <div className="line login-line mt-0" />
                <div className="or-carrier">
                    <p className="text-14">{formatMessage(messages.or)}</p>
                </div>
                <div className="line login-line mt-0" />
            </div>
        );
    }

    return (
        <div className={"flex align-center justify-between line-holder mb-10 mt-11 $"}>
            <div className="line register-line" />
            <div className="or-carrier">
                <p className="text-14 another-grey-color mb-0">{formatMessage(messages.or)}</p>
            </div>
            <div className="line register-line" />
        </div>
    );
}

export default OrSeparatorLine;
