import React, { useState } from "react";

const InputFancy = ({
    id = "",
    className = "",
    readOnly,
    type,
    name = "",
    placeholder,
    defaultValue,
    value = "",
    maxLength = "",
    errorMessage = "",
    onChange,
    inputClassName = "",
    errorClassName = "error-element",
    errorIcon = true,
    error,
    onBlur,
    onFocus,
    disabled = false,
    onKeyPress,
    autoComplete = "off",
    ...rest
}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <div className={`form-element fancy-input ${className} ${error ? errorClassName : ""}`} data-role="fieldcontain">
            <div className="position-relative">
                <input
                    {...rest}
                    className={`form-input ${inputClassName}`}
                    onFocus={(event) => {
                        onFocus?.(event);
                        setIsFocused(true)
                    }}
                    onBlur={(event) => {
                        onBlur(event);
                        setIsFocused(false);
                    }}
                    id={id}
                    name={name}
                    type={type}
                    onKeyPress={onKeyPress}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    disabled={disabled}
                    autoComplete={autoComplete}
                />
                <span className={`form-label ${isFocused || value ? "lable-top" : ""}`}>{placeholder}</span>
                {error && (
                    <>
                        {errorMessage && errorIcon && <i className="fas fa-exclamation-triangle" />}
                        {errorMessage && <label className="error-label text-left">{errorMessage}</label>}
                    </>
                )}
            </div>
        </div>
    );
};

export default InputFancy;
