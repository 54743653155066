/* eslint-disable no-alert */
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Google from "assets/images/svg/google.svg";
import Auth from "utils/auth0";
import { TENANTS_LIST } from "constants/index";
import { selectMobilePlatform, selectTenant } from "store/app/selectors";

const SocialLogin = ({ isSingleLine = false, isButtonTextShow = false, isApp = false, currentTenant, mobilePlatform }) => {
    function onClickSignIn(socialName) {
        if (isApp) {
            window?.ReactNativeWebView?.postMessage?.(JSON.stringify({ socialLogin: socialName }));
        } else {
            Auth.signInWithProvider(socialName);
        }
    }

    const socialLoginButtons = [
        {
            text: "Login via Google",
            onClick: () => onClickSignIn("google-oauth2"),
            className: "google",
            icon: <Google />,
            isTenantNotAllow: [],
        },
        {
            text: "Login via Apple",
            onClick: () => onClickSignIn("apple"),
            className: "apple",
            icon: <i className="fab fa-apple" />,
            isTenantNotAllow: [TENANTS_LIST.SACHWERTINVEST],
        },
        {
            text: "Login via Facebook",
            onClick: () => onClickSignIn("facebook"),
            className: "facebook",
            icon: <i className="fab fa-facebook-f" />,
            isTenantNotAllow: [TENANTS_LIST.SACHWERTINVEST],
        },
        {
            text: "Login via Linkedin",
            onClick: () => onClickSignIn("linkedin"),
            className: "linkedin",
            icon: <i className="fab fa-linkedin-in" />,
            isTenantNotAllow: [TENANTS_LIST.SACHWERTINVEST],
        },
    ];

    const renderSocialLoginButtons = (item, index) => {
        return (
            <div key={index}>
                <button onClick={item.onClick} className={item.className}>
                    <>
                        {item.icon} {isButtonTextShow ? item.text : null}
                    </>
                </button>
            </div>
        );
    };

    return (
        <div
            className={`social-button-holder ${!isButtonTextShow ? "flex  flex-row-social justify-center" : ""} ${
                !isSingleLine ? "mt-5" : ""
            }`}
        >
            {socialLoginButtons.map((item, index) => {
                return item.isTenantNotAllow.includes(currentTenant)
                    ? null
                    : isApp
                      ? !(mobilePlatform === "android" && item.className === "apple") && renderSocialLoginButtons(item, index)
                      : renderSocialLoginButtons(item, index);
            })}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentTenant: selectTenant,
    mobilePlatform: selectMobilePlatform,
});

export default connect(mapStateToProps)(SocialLogin);
