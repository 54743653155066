import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { compose } from "recompose";
import * as yup from "yup";
import cn from "classnames";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Sentry from "@sentry/nextjs";

import { selectIsCurrentTenantFinexity, selectIsProdEnv, selectTenant } from "store/app/selectors";
import { useFormattedMessage, useScrollToTop } from "hooks";

import Auth from "utils/auth0";
import { email, password } from "i18n/messages/formElements";
import { sendFormError, access_denied, blocked_user } from "i18n/messages/errors";
import { validationObject } from "utils/yupValidation";
import { login as loginButtonTitle } from "i18n/messages/buttonTitles";

import Link from "components/NavLink";
import InputFancy from "components/InputFancy";
import ErrorMessage from "components/ErrorMessage";
import PasswordInput from "components/Input/passwordInput";

import { AUTH_USER_ERROR_MSG, GENERAL_CONSTANTS } from "constants/index";
import FINX_COOKIE from "common/constants";
import { slackMessage } from "api";
import OrSeparatorLine from "./OrSeparatorLine";
import { storeAuthTokenData } from "store/user/account/actions";
import NewLoader from "./NewLoader";
import { callTheInitialKYCMethods } from "store/user/kyc/actions";

const messages = {
    ...email,
    ...password,
    sendFormError,
    access_denied,
    blocked_user,
    loginButtonTitle,
    or: "auth_account_or",
};

const LoginForm = (props) => {
    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        setErrors,
        handleSubmit,
        isShowSeparatorLine = true,
        selectedEmail,
        setFieldValue,
    } = props;

    const { requestError } = errors;
    const { formatMessage } = useFormattedMessage();
    const errorMessage = requestError && formatMessage(messages[requestError]);
    const [isLoaded, setIsLoaded] = useState(false);
    useScrollToTop(true);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (selectedEmail) {
            setFieldValue("email", selectedEmail);
        }
    }, []);

    function handleInputChange(e) {
        if (errorMessage) {
            setErrors({ requestError: "" });
        }
        handleChange(e);
    }

    return (
        <form
            method="post"
            autoComplete="off"
            onSubmit={handleSubmit}
            className={`form mt-10 mx-auto ft-user-login-form login-form${Object.keys(errors).length ? " fail-validation" : ""}`}
        >
            <div className="text-center pb-5">
                <InputFancy
                    type="text"
                    id="l-email"
                    name="email"
                    className="lf-email-input"
                    placeholder={formatMessage(messages.emailPlaceholder)}
                    onChange={(e) => handleInputChange(e)}
                    value={values.email}
                    onBlur={handleBlur}
                    errorMessage={errors.email ? formatMessage(messages[errors.email]) : null}
                    error={touched.email && errors.email}
                />
                <PasswordInput
                    id="l-password"
                    name="password"
                    className="lf-password-input"
                    placeholder={formatMessage(messages.passwordPlaceholder)}
                    onChange={(e) => handleInputChange(e)}
                    value={values.password}
                    onBlur={handleBlur}
                    errorMessage={errors.password ? formatMessage(messages[errors.password]) : null}
                    error={touched.password && errors.password}
                />
                <div className="error-element">
                    {errorMessage && (
                        <ErrorMessage className="auth0-error">
                            {errorMessage}
                            {requestError === AUTH_USER_ERROR_MSG.BLOCKED ? (
                                <a className="primary-color" href={`mailto:${GENERAL_CONSTANTS.FINEXITY_SUPPORT_EMAIL}`}>
                                    {" "}
                                    {GENERAL_CONSTANTS.FINEXITY_SUPPORT_EMAIL}
                                </a>
                            ) : null}
                        </ErrorMessage>
                    )}
                </div>
                <div className={`flex column mt-10 ${props.buttonClassName}`}>
                    <button
                        type="submit"
                        disabled={!isLoaded || isSubmitting}
                        className={cn({
                            "disable-grey": isSubmitting,
                        })}
                    >
                        {isSubmitting ? (
                            <>
                                <NewLoader /> {formatMessage(loginButtonTitle)}
                            </>
                        ) : (
                            formatMessage(loginButtonTitle)
                        )}
                    </button>
                </div>
                <div className="column forgot">
                    <span className="text-16 fw-600 primary-color text-center d-block">
                        <Link href="/password-remind" className={`${props.linkClassName}`}>
                            {formatMessage(messages.forgotPassword)}
                        </Link>
                    </span>
                </div>
                {isShowSeparatorLine && <OrSeparatorLine isLogin />}
            </div>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    isTenantFinexity: selectIsCurrentTenantFinexity,
    isProdEnv: selectIsProdEnv,
    currentTenant: selectTenant,
});

const mapDispatchToProps = (dispatch) => ({
    callTheInitialKYCMethods: () => dispatch(callTheInitialKYCMethods()),
    storeAuthTokenData: (token) => storeAuthTokenData(token),
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: () => ({ email: "", password: "" }),
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: () =>
            yup.object().shape({
                email: validationObject.email,
                password: validationObject.loginPassword,
            }),
        handleSubmit: (values, { setSubmitting, setStatus, setErrors, props }) => {
            const loweredCaseUserEmail = values.email.trim().toLowerCase();
            setSubmitting(true);
            try {
                Cookies.set(FINX_COOKIE.USER_EMAIL, loweredCaseUserEmail);
                const isTenantFinexity = props.isTenantFinexity;

                if (!isTenantFinexity) {
                    Auth.customLogin({ username: loweredCaseUserEmail, password: values.password })
                        .then((response) => response.json())
                        .then((response) => {
                            if (response?.id_token) {
                                props.storeAuthTokenData(response.id_token);

                                localStorage.setItem(FINX_COOKIE.FINX_SESSION, response.id_token);
                                localStorage.setItem(FINX_COOKIE.REFRESH_TOKEN, response.refresh_token);
                                localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, response.access_token);

                                window.location = "/dashboard";
                            } else {
                                Cookies.remove(FINX_COOKIE.USER_EMAIL);
                                const error = response;
                                setStatus({ success: false });
                                if (
                                    error?.error_description === AUTH_USER_ERROR_MSG.BLOCKED_MSG ||
                                    error?.error === AUTH_USER_ERROR_MSG.TO_MANY_ATTEMPT
                                ) {
                                    slackMessage(loweredCaseUserEmail);
                                    setErrors({ requestError: AUTH_USER_ERROR_MSG.BLOCKED });
                                } else if (error?.error_description === AUTH_USER_ERROR_MSG.WRONG_CRED) {
                                    setErrors({ requestError: AUTH_USER_ERROR_MSG.DENIED });
                                } else {
                                    Sentry.captureMessage(error.error_description || error.description);
                                    setErrors({ requestError: "sendFormError" });
                                }
                            }
                        })
                        .catch((error) => {
                            Cookies.remove(FINX_COOKIE.USER_EMAIL);
                            setStatus({ success: false });
                            if (
                                error?.error_description === AUTH_USER_ERROR_MSG.BLOCKED_MSG ||
                                error?.error === AUTH_USER_ERROR_MSG.TO_MANY_ATTEMPT
                            ) {
                                slackMessage(loweredCaseUserEmail);
                                setErrors({ requestError: AUTH_USER_ERROR_MSG.BLOCKED });
                            } else if (error?.error_description === AUTH_USER_ERROR_MSG.WRONG_CRED) {
                                setErrors({ requestError: AUTH_USER_ERROR_MSG.DENIED });
                            } else {
                                Sentry.captureException(error);
                                setErrors({ requestError: "sendFormError" });
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                } else {
                    Auth.login({ username: loweredCaseUserEmail, password: values.password }, (error) => {
                        if (error) {
                            Cookies.remove(FINX_COOKIE.USER_EMAIL);
                            setSubmitting(false);
                            setStatus({ success: false });

                            if (error.code === AUTH_USER_ERROR_MSG.DENIED) {
                                setErrors({ requestError: AUTH_USER_ERROR_MSG.DENIED });
                            } else if (
                                error.code === AUTH_USER_ERROR_MSG.BLOCKED ||
                                error.code === AUTH_USER_ERROR_MSG.TO_MANY_ATTEMPT
                            ) {
                                // For slack message
                                if (error.code === AUTH_USER_ERROR_MSG.BLOCKED) {
                                    slackMessage(loweredCaseUserEmail);
                                }
                                setErrors({ requestError: AUTH_USER_ERROR_MSG.BLOCKED });
                            } else {
                                Sentry.captureMessage(error.error_description || error.description);
                                setErrors({ requestError: "sendFormError" });
                            }
                        }
                    });
                }
            } catch (error) {
                Sentry.captureException(error);
                Cookies.remove(FINX_COOKIE.USER_EMAIL);
                setStatus({ success: false });
                setSubmitting(false);
                error.message && setErrors({ requestError: "sendFormError" });
            }
        },
        displayName: "LoginForm",
    }),
)(LoginForm);
